<template>
  <vca-row>
    <div>
      <h3>{{ $t("network.crews.list.entity_filter.header") }}</h3>
      <div>
        <vca-checkbox
          v-for="entity in entities"
          v-model="value.entities"
          :key="entity"
          :id="entity"
          >{{ $t("network.crews.list.entity_filter." + entity) }}</vca-checkbox
        >
      </div>
    </div>
  </vca-row>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "CrewFilter",
  props: {
    value: {
      type: Object,
      default: null,
    },
  },
  computed: {
    ...mapGetters({
      entities: "crews/entities",
    }),
  },
};
</script>
