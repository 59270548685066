<template>
  <div @click="generateExport()">
    <slot></slot>
  </div>
</template>
<script>
export default {
  name: "CsvExport",
  props: {
    data: {
      type: Array,
      default() {
        return [];
      },
    },
    filename: {
      type: String,
      default: "export.csv",
    },
    delimiter: {
      type: String,
      default: ";",
    },
    labels: {
      type: Object,
      default() {
        return null;
      },
    },
  },
  methods: {
    generateExport() {
      if (!this.data.length > 0) {
        return false;
      }

      let csvContent = "data:text/csv;charset=utf-8,";
      let keys = Object.keys(this.data[0]);
      if (this.labels) {
        keys = keys.map((el) => (this.labels[el] ? this.labels[el] : el));
      }

      keys = keys.join(this.delimiter);

      csvContent += [
        keys,
        ...this.data.map((item) => Object.values(item).join(this.delimiter)),
      ]
        .join("\n")
        .replace(/(^\[)|(\]$)/gm, "");

      const data = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", data);
      link.setAttribute("download", this.filename);
      link.click();
    },
  },
};
</script>
