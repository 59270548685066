<template>
  <div>
    <CrewForm
      v-model="current"
      @submit="update"
      @remove_city="removeCity"
      @add_city="addCity"
    />
    <div class="vca-center">
      <vca-cancel-button
        @click="deleteCrew()"
        :placeholder="$t('button.delete')"
      />
    </div>
  </div>
</template>

<script>
import CrewForm from "@/components/network/crews/CrewForm";
export default {
  name: "CrewsEdit",
  components: { CrewForm },
  computed: {
    current: {
      get() {
        return this.$store.state.crews.current;
      },
      set(value) {
        this.$store.commit("crews/current", value);
      },
    },
  },
  methods: {
    update() {
      if (!confirm(this.$t("crews.messages.update"))) {
        return false;
      }
      this.$store.dispatch({ type: "crews/updateReq" }).then((response) => {
        this.current = response;
        this.$store.commit(
          "crews/asp_selection_state",
          this.current.asp_selection
        );
      });
    },
    deleteCrew() {
      if (!confirm(this.$t("crews.messages.delete"))) {
        return false;
      }
      this.$store.dispatch({ type: "crews/delete" }).then(() => {
        this.$store.dispatch({ type: "crews/list" });
      });
    },
    removeCity(index) {
      this.$store.commit("crews/remove_current_city", index);
    },
    addCity(val) {
      if (this.current.cities == undefined) {
        this.current["cities"] = [];
      }
      if (this.current.cities.find((el) => el.city == val.city)) {
        this.notification({
          title: this.$t("messages.title.warn"),
          body: this.$t("messages.crew.city_conflict"),
          type: "warn",
        });
        return;
      }
      this.$store.commit("crews/add_current_city", val);
    },
  },
};
</script>
<style lang="scss">
.add-city {
  button {
    margin: 5px 0 10px 10px;
  }
}
</style>
